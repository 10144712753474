import { datadogRum } from "@datadog/browser-rum";
import type { NextWebVitalsMetric } from "next/app";
import { config } from "server/config";

import { SERVICE_NAME } from "lib/constants";
import {
  getEnvironment,
  isProduction,
  isSandbox,
  isStaging,
  isTest,
} from "../utils/environment";

export function initializeDatadogRum() {
  const shouldTrackToDatadog = isStaging || isSandbox || isProduction;
  /**
   * No need to log this while running tests.
   */
  if (!shouldTrackToDatadog && !isTest) {
    console.info(
      `Datadog RUM not initialized: only enabled in sandbox, staging, and production.`
    );
    return;
  }

  const isSSR = typeof window === "undefined";
  if (isSSR) {
    return;
  }

  const isRunningDatadogSynthetics = window._DATADOG_SYNTHETICS_BROWSER;
  if (isRunningDatadogSynthetics) {
    console.info("Datadog RUM not initialized: running in Datadog Synthetics.");
    return;
  }

  // Early return here to avoid console.logs in our tests
  if (config().NODE_ENV === "test") {
    return;
  }

  const applicationId = config().NEXT_PUBLIC_DATADOG_APP_ID;
  const clientToken = config().NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  if (!applicationId || !clientToken) {
    console.info(
      "Datadog RUM not initialized: missing applicationId or clientToken."
    );
    return;
  }

  // Enables distributed tracing for any `*.zapier.com` or `*.zapier-staging.com` APIs.
  // See: https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
  type PropagatorType = "datadog" | "tracecontext";
  const allowedTracingUrls: {
    match: RegExp | string;
    propagatorTypes: PropagatorType[];
  }[] = [
    {
      match: window.origin,
      propagatorTypes: ["tracecontext"],
    },
    {
      match: /https:\/\/(.*\.)?zapier(-staging)?\.com/,
      propagatorTypes: ["datadog"],
    },
  ];

  // See https://docs.datadoghq.com/real_user_monitoring/installation/?tab=us#initialization-parameters
  // for more information on what these individual parameters do.
  datadogRum.init({
    applicationId,
    clientToken,
    /**
     * We have decided to disable the session replay for users since we did not use it.
     * https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/#disable-session-replay
     */
    sessionReplaySampleRate: 0,
    service: SERVICE_NAME,
    version: config().NEXT_PUBLIC_COMMIT_SHA,
    useCrossSiteSessionCookie: true,
    env: getEnvironment(),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls,
  });
}

const vitalsMapper: { [key: string]: string } = {
  // Length of time it takes for the page to start and finish hydrating (in ms)
  "Next.js-hydration": "nextJsHydration",
  // Length of time it takes for a page to start rendering after a route change (in ms)
  "Next.js-route-change-to-render": "nextJsRouteChangeToRender",
  // Length of time it takes for a page to finish render after a route change (in ms)
  "Next.js-render": "nextJsRender",

  // Web Vitals - https://web.dev/vitals/
  CLS: "cumulativeLayoutShift",
  FCP: "firstContentfulPaint",
  FID: "firstInputDelay",
  LCP: "largestContentfulPaint",
  TTFB: "timeToFirstByte",
};

// Measure and report web vitals that Next.js tracks to Datadog.
// See: https://nextjs.org/docs/advanced-features/measuring-performance
export function nextJsReportWebVitals(metric: NextWebVitalsMetric) {
  const mappedName = vitalsMapper[metric.name] || metric.name;

  const shouldTrackVitals = isStaging || isProduction;
  if (!shouldTrackVitals) {
    console.info(
      `Datadog RUM: would have tracked web vital ${mappedName} - ${metric.value}.`
    );
    return;
  }

  datadogRum.addAction(`Web Vital: ${mappedName}`, {
    [mappedName]: metric.value,
  });
}
