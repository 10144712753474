/**
 * When adding global CSS,
 * import it here but also in the `packages/service/playwright/index.tsx` file.
 *
 * The `packages/service/playwright/index.tsx` file is used for Playwright component testing.
 */
import "@zapier/design-tokens/custom-properties.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-phone-number-input/style.css";
import "../css/globals.css";

import type { AppProps } from "next/app";

import { fontSans } from "lib/fonts";
import { initializeDatadogRum } from "observability";
import { useEffect } from "react";
import { cn } from "utils/cn";
import { trpc } from "utils/trpc";

initializeDatadogRum();

type AppPropsWithUser = AppProps & {
  pageProps: {
    user: any;
  };
};
function App({ Component, pageProps }: AppPropsWithUser) {
  useBodyClassNames("min-h-screen", "font-sans", fontSans.variable);

  return (
    /**
     * The `isolate` class is used to make sure all popovers and modals
     * that render OUTSIDE the `main` will always stay on top of `main`,
     * ignoring z-index values for anything inside main
     */
    <div className={cn("isolate min-h-screen font-sans", fontSans.variable)}>
      <Component {...pageProps} />
    </div>
  );
}

/**
 * Applies class names to the body element, which
 * helps with styling when rendering React portals
 * @param tokens class names to apply
 */
function useBodyClassNames(...tokens: string[]) {
  useEffect(() => {
    if (document) {
      document.body.classList.add(...tokens);
    }
  }, [tokens]);
}

export default trpc.withTRPC(App);
