import { TRPCClientError } from "@trpc/client";
import { config } from "server/config";

const returnPath = (path?: string) => {
  let returnToPath: string;

  if (!path) {
    const currentLocation = window.location.toString();
    returnToPath =
      currentLocation.replace(new URL(currentLocation).origin, "") || "/";
  } else {
    returnToPath = path;
  }

  return `returnTo=${encodeURIComponent(returnToPath)}`;
};

export function relogin(returnTo?: string) {
  return window.location.assign(`/api/auth/login?${returnPath(returnTo)}`);
}

export function signupUrl(returnTo?: string) {
  let redirectPath = config().NEXT_PUBLIC_INTERFACES_BASE_URL;

  if (returnTo) {
    redirectPath += `?${returnPath(returnTo)}`;
  }

  let url = `${config().NEXT_PUBLIC_ZAPIER_API_BASE_URL}/sign-up`;

  if (redirectPath) url += `?next=${encodeURIComponent(redirectPath)}`;

  return url;
}

export function handleSignUp(returnTo?: string) {
  return window.location.assign(signupUrl(returnTo));
}

export function shouldRelogin(error: unknown) {
  if (error instanceof TRPCClientError && error.data?.code) {
    switch (error.data.code) {
      case "auth:not-logged-in":
      case "tables:auth:missing_access_token":
        return true;
    }
  }
  return false;
}
