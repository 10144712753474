import { isProduction, isSandbox, isStaging } from "utils/environment";
import { ZodSchema, z } from "zod";

export const ConfigSchema = z.object({
  // TODO: Remove `.optional()` once we have the Interfaces standalone API up
  // and running.
  INTERFACESAPI_ORIGIN: optionalOnClient(z.string().url()).optional(),

  NEXT_PUBLIC_BASE_DOMAIN: z.string(),
  NEXT_PUBLIC_INTERFACES_BASE_URL: z.string().url(),
  NEXT_PUBLIC_ZSL_API_BASE_URL: z.string().url(),
  NEXT_PUBLIC_COMMIT_SHA: optionalInDev(z.string()),
  NEXT_PUBLIC_ZAPIER_API_BASE_URL: z.enum([
    "https://zapier-staging.com",
    "https://zapier.com",
    "http://localhost:8001",
    "http://localhost:8080",
  ]),
  NEXT_PUBLIC_MIXPANEL_PROJECT_ID: optionalInDev(z.string()),
  NEXT_PUBLIC_SPLIT_IO_CLIENT_AUTH_KEY: optionalInDev(z.string()),
  NEXT_PUBLIC_DATADOG_APP_ID: optionalInDev(z.string()),
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: optionalInDev(z.string()),
  NEXT_PUBLIC_SPLIT_LOGGING: z.string().default(""),

  NEXT_PUBLIC_CHAT_LEGAL_TEXT: z.string(),
  NEXT_PUBLIC_CHAT_LEGAL_URL: z.string(),

  REDIS_URL: optionalOnClient(z.string()),

  UPSTASH_URL: z.string().url().optional(),
  UPSTASH_TOKEN: z.string().optional(),
  UPSTASH_TIMEOUT: z.number().optional().default(5000),

  NEXT_PUBLIC_APP_ENV: optionalOnClient(
    z.enum(["local", "sandbox", "staging", "production"]).default("local")
  ),
  NODE_ENV: optionalOnClient(
    z.enum(["development", "test", "production"])
  ).default("development"),

  LOG_LEVEL: optionalOnClient(
    z.enum(["trace", "debug", "info", "error"]).default("info")
  ),
  PRISMA_LOGS: z
    .array(z.enum(["query", "info", "warn", "error"]))
    .optional()
    .default([]),

  PORT: optionalOnClient(z.string().default("3000")),
  SESSION_SECRET: optionalOnClient(z.string()),

  DATADOG_API_KEY: z.string().optional(),
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  SENTRY_AUTH_TOKEN: z.string().optional(),

  TABLES_SPLIT_IO_NODEJS_AUTH_KEY: optionalInDev(optionalOnClient(z.string())),
  SPLIT_IO_NODEJS_AUTH_KEY: optionalInDev(optionalOnClient(z.string())),
  SPLIT_ADMIN_API_KEY: z.string().optional(),

  NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY: optionalInDev(z.string()),
  AWS_INTERFACES_UPLOADS_BUCKET: optionalOnClient(z.string()),
  AWS_UPLOADCARE_DELETION_ARN: optionalOnClient(z.string()),
  AWS_DEFAULT_REGION: optionalOnClient(z.string()),
  NEXT_PUBLIC_INTERFACES_CDN_BASE_URL: z
    .string()
    .url()
    .refine((url) => {
      return !url.endsWith("/");
    }, "must not end in a backslash"),
  UPLOADCARE_SECRET: optionalInDev(optionalOnClient(z.string())),
  UPLOADCARE_METADATA_SECRET: optionalOnClient(z.string()),
  UPLOADCARE_LOCALHOST_WEBHOOKS_ENABLED: z
    .union([z.literal("true"), z.literal("false")])
    .default("false"),
  UPLOADCARE_STORAGE_NAME: optionalInDev(optionalOnClient(z.string())),
  UPLOADCARE_WEBHOOK_SECRET: optionalInDev(optionalOnClient(z.string())),

  STRIPE_API_KEY: optionalInDev(optionalOnClient(z.string())),
  STRIPE_CONNECT_ACCOUNT_UPDATED_WEBHOOK_KEY: optionalInDev(
    optionalOnClient(z.string())
  ),
  STRIPE_CONNECT_ACCOUNT_APPLICATION_DEAUTHORIZED_WEBHOOK_KEY: optionalInDev(
    optionalOnClient(z.string())
  ),
  STRIPE_CONNECT_TAX_SETTINGS_UPDATED_WEBHOOK_KEY: optionalInDev(
    optionalOnClient(z.string())
  ),
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: optionalInDev(z.string()),

  INTERFACES_CLI_APP_CHECKSUM: optionalInDev(optionalOnClient(z.string())),
  INTERFACES_CLI_APP_ID: optionalInDev(optionalOnClient(z.string())),

  HEALTH_CHECK_API_KEY: optionalOnClient(z.string()),

  ZAPIER_API_BASE_URL: optionalOnClient(
    z.enum([
      "https://zapier-staging.com",
      "https://zapier.com",
      "http://localhost:8001",
      "http://localhost:8081",
      "http://localhost:8080",
    ])
  ),
  ZAPIER_TABLES_API_BASE_URL: optionalOnClient(
    z
      .enum([
        "https://tables.zapier-staging.com",
        "https://tables.zapier.com",
        "http://localhost:8000",
      ])
      .default("https://tables.zapier-staging.com")
  ),
  ZAPIER_CHATBOTS_API_BASE_URL: optionalOnClient(z.string().url()),
  ZAPIER_CHATBOTS_INTERNAL_API_KEY: z.optional(z.string()),
  NEXT_PUBLIC_ZAPIER_CHATBOTS_URL: z.string().url(),
  ZAP_CALLBACK_URL: z.string().url().optional(),
  NEXT_PUBLIC_ZAPIER_TABLES_UI_BASE_URL: z.enum([
    "https://tables.zapier-staging.com",
    "https://tables.zapier.com",
    "http://localhost:8000",
  ]),
  VERCEL: optionalOnClient(z.enum(["0", "1"]).default("0")),
  VERCEL_URL: optionalOnClient(z.string().optional()),
  VERCEL_TEAM_ID: optionalOnClient(optionalInDev(z.string())),
  AUTH_BEARER_TOKEN: optionalOnClient(optionalInDev(z.string())), // TODO: rename to VERCEL_AUTH_BEARER_TOKEN
  VERCEL_PROJECT_ID: optionalOnClient(optionalInDev(z.string())),
  VERCEL_GIT_COMMIT_SHA: optionalOnClient(optionalInDev(z.string())),

  // TODO: The names of our config keys are exposed in our frontend source, but only
  // the ones with NEXT_PUBLIC_ prefix actually have their values exposed.
  //
  // Should we do something about this?
  JWT_PRIVATE_KEY: optionalOnClient(z.string()),

  ZAPIER_CLIENT_ID: optionalOnClient(z.string()),
  ZAPIER_CLIENT_SECRET: optionalOnClient(z.string()),
  ZAPIER_ISSUER_BASE_URL: optionalOnClient(z.string().url()),

  OPENAI_API_KEY: optionalOnClient(
    z.string().refine((key) => key.startsWith("sk-"))
  ),
  OPENAI_MAX_TOKENS: optionalOnClient(
    optionalInDev(z.coerce.number().int().default(2048))
  ),
  OPENAI_ORG_ID: optionalOnClient(
    z.string().refine((key) => key.startsWith("org-"))
  ),

  GOOGLE_CLOUD_API_KEY: optionalOnClient(optionalInDev(z.string())),
  GOOGLE_CLOUD_PROJECT_ID: optionalOnClient(optionalInDev(z.string())),
  NEXT_PUBLIC_VISIBLE_RECAPTCHA_SITE_KEY: optionalInDev(z.string()),
  NEXT_PUBLIC_HIDDEN_RECAPTCHA_SITE_KEY: optionalInDev(z.string()),

  DEFAULT_TABLES_LIMIT: optionalOnClient(z.coerce.number().int().default(10)),

  RATE_LIMITING_ENABLED: optionalOnClient(
    z
      .string()
      .default("true")
      .transform((v) => v === "true")
  ),

  RATE_LIMITING_MAX_REQUESTS: optionalOnClient(
    z.coerce.number().int().default(250)
  ),
  RATE_LIMITING_WINDOW_SIZE_MS: optionalOnClient(
    z.coerce
      .number()
      .int()
      .default(60 * 1000)
  ),

  HOST_RATE_LIMITING_MAX_REQUESTS: optionalOnClient(
    z.coerce.number().int().default(20)
  ),
  HOST_RATE_LIMITING_WINDOW_SIZE_MS: optionalOnClient(
    z.coerce.number().int().default(1000)
  ),

  API_RATE_LIMITING_MAX_REQUESTS: optionalOnClient(
    z.coerce.number().int().default(250)
  ),
  API_RATE_LIMITING_WINDOW_SIZE_MS: optionalOnClient(
    z.coerce
      .number()
      .int()
      .default(60 * 1000)
  ),

  CHAT_RATE_LIMITING_MAX_REQUESTS: optionalOnClient(
    z.coerce.number().int().default(20)
  ),
  CHAT_RATE_LIMITING_WINDOW_SIZE_MS: optionalOnClient(
    z.coerce
      .number()
      .int()
      .default(60 * 1000)
  ),

  INTERFACES_EDGE_CONFIG: optionalOnClient(optionalInDev(z.string().url())),

  LOCAL_TRPC_LATENCY: optionalOnClient(
    z.coerce.number().int().nonnegative().lte(5000).default(0)
  ),

  /**
   * Used for OTP authorization for rodeo users.
   *
   * Fetching emails sent to @zapier.rodeo users is a bit cumbersome:
   * https://engineering.zapier.com/guides/monolith/sending-transactional-emails/#viewing-emails-sent
   *
   * As such, we decided to use a hardcoded OTP code.
   */
  RODEO_USER_OTP: optionalOnClient(optionalInDev(z.string())),

  /* ---- STYTCH ---- */
  STYTCH_PROJECT_ID: optionalOnClient(z.string()),
  STYTCH_PUBLIC_TOKEN: optionalOnClient(z.string()),
  STYTCH_SECRET_TOKEN: optionalOnClient(z.string()),
  STYTCH_API_BASE_URL: optionalOnClient(z.string().url()),
  STYTCH_API_GOOGLE_OAUTH_BASE_URL: optionalOnClient(z.string().url()),
  STYTCH_MEMBER_CREATED_WEBHOOK_API_KEY: optionalOnClient(z.string()),
});

export type Config = z.infer<typeof ConfigSchema>;

function optionalInDev(schema: ZodSchema): ZodSchema {
  const isDev: boolean = !isStaging && !isSandbox && !isProduction;

  return isDev ? schema.optional() : schema;
}

function optionalOnClient(schema: ZodSchema): ZodSchema {
  const isClient = typeof window !== "undefined";
  return isClient ? schema.optional() : schema;
}
